<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card
      ><v-card-title>
        {{ $t("boxes.reservation") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="reserva-form"
        @submit.prevent="validateFormReserva('reserva-form')"
      >
        <v-card-text>
          <v-row>
            <v-col class="py-0" cols="12">
              <v-row align="center">
                <v-col cols="6">
                  <label v-text="$t('boxes.tattooer')" />
                  <v-select
                    :items="tattooers"
                    v-model="res.tattooer"
                    single-line
                    item-value="id"
                    data-vv-name="tattooer"
                    dense
                    outlined
                    hide-details
                    data-vv-validate-on="blur"
                    @change="
                      fetchAllVacationsTattooer(
                        res.tattooer,
                        $store.state.auth.user.studio.id
                      )
                    "
                    :error-messages="errors.collect('reserva-form.tattooer')"
                    v-validate="'required'"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{
                        data.item.user.last_name !== null &&
                        data.item.user.last_name !== "null"
                          ? data.item.user.last_name
                          : ""
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{
                        data.item.user.last_name !== null
                          ? data.item.user.last_name
                          : ""
                      }}
                    </template>
                  </v-select>
                </v-col>
                <v-col class="py-0" cols="6">
                  <label v-text="$t('box')" />
                  <v-select
                    :items="boxes"
                    v-model="res.id"
                    single-line
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    data-vv-name="boxes"
                    :error-messages="errors.collect('reserva-form.boxes')"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    style="display: inline-block; width: 100%"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" md="4" lg="5">
                  <label v-text="$t('events.date')" />
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    transition="scale-transition"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="
                          errors.collect('reserva-form.startDate')
                        "
                        class="filters"
                        readonly
                        v-bind:class="{
                          'error--text': dateError || horarioError,
                        }"
                        v-bind="attrs"
                        v-on="on"
                        :value="
                          res.date ? $d(new Date(res.date), 'date2digits') : ''
                        "
                        ><template v-slot:prepend-inner>
                          <!--Aqui va el v-icon -->
                          <v-icon
                            small
                            class="pt-0 pb-1"
                            style="margin-top: 5px"
                            >$appointments</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="res.date"
                      no-title
                      scrollable
                      color="primary"
                      :min="today"
                      @input="menu2 = false"
                      :allowed-dates="allowedDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="5" sm="3" md="3" lg="3" class="py-0">
                  <label v-text="$t('events.startTime')" />
                  <v-select
                    :items="hours"
                    v-model="res.start"
                    dense
                    outlined
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    v-bind:class="{ 'error--text': timeError }"
                    data-vv-name="startTime"
                    :error-messages="errors.collect('reserva-form.startTime')"
                  ></v-select>
                </v-col>
                <v-col cols="1">
                  <p class="mt-5 mb-0 pt-0">-</p>
                </v-col>

                <v-col cols="5" sm="3" md="3" lg="3" class="py-0">
                  <label v-text="$t('events.endTime')" />

                  <v-select
                    :items="hours"
                    v-model="res.end"
                    hide-details
                    v-bind:class="{ 'error--text': timeError }"
                    dense
                    data-vv-validate-on="blur"
                    :v-validate="res.associate ? '' : 'required'"
                    :error-messages="errors.collect('reserva-form.endTime')"
                    data-vv-name="endTime"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="dateError || horarioError"
                class="py-0"
                style="width: 100%; margin: 0 auto"
              >
                <v-col
                  class="
                    login_error
                    white--text
                    pa-1
                    text-center
                    rounded-xl
                    my-0
                  "
                  >Está fuera del horario establecido</v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 30px; width: 100px"
            elevation="0"
            hide-details
            class="mr-2"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            elevation="0"
            style="height: 30px; width: 100px !important"
            ><v-icon small style="margin-right: 5px">$save</v-icon
            >{{ $t("save", { name: "" }) }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "BoxReservationForm",
  data() {
    return {
      menu2: false,
    };
  },
  methods: {
    validateFormReserva(scope) {
      this.errors.clear();
      console.log("VALIDANDO");
      this.$validator.validateAll(scope).then((result) => {
        let timeVal = true;
        if (this.res.start >= this.res.end) {
          timeVal = false;
        }
        console.log(result, timeVal);
        if (result && timeVal) {
          this.reservation();
        } else {
          console.log("NO VALIDO");
        }
      });
    },
    ...mapGetters("boxes", ["dayOfWeek"]),
    ...mapGetters("timetables", ["getDayTimetable"]),
    allowedDates(date) {
      let h = this.getDayTimetable()(
        date,

        this.$store.getters["auth/isStudio"]
          ? this.$store.state.auth.user.studio.id
          : this.$store.state.boxes.studioId,
        this.res.tattooer
      );
      if (h) {
        return h.length > 0;
      }
      return false;
    },
    ...mapActions("tattooers", ["reservationBox", "reservationBoxUpdate"]),
    reservation() {
      if (this.actionState == "save") {
        //console.log(this.res);
        this.reservationBox({
          tattooer_id: this.res.tattooer,
          formData: this.res,
        }).then((data) => {
          //console.log(data);
          if (data.count === 0) {
            this.$alert("Guardado correctamente", "", "");
            this.$emit("reload");

            this.dialog = false;
          } else {
            console.log(data);
            if (data.sentencia)
              this.$alert("El artista asignado está ocupado en este horario");
            else this.$alert("No hay disponibilidad para esta fecha", "", "");
          }
        });
      } else {
        //console.log(this.res);
        this.reservationBoxUpdate({
          tattooer_id: this.res.tattooer,
          formData: this.res,
        }).then((data) => {
          //console.log(data);
          if (data.sentencia == false) {
            this.$alert("Guardado correctamente", "", "");
            this.$emit("reload");

            this.dialog = false;
          } else {
            this.$alert("No hay disponibilidad para esta fecha", "", "");
          }
        });
      }
    },
  },
  watch: {},
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  computed: {
    ...mapState("boxes", [
      "reservaState",
      "dialogState",
      "actionState",
      "boxes",
      "tattooers",
      "timetableStudio",
    ]),
    ...mapGetters("boxes", ["today"]),
    ...mapGetters("timetables", ["hours"]),
    res: {
      get() {
        return this.reservaState;
      },
      set(value) {
        this.$store.commit("boxes/SET_BOX", { value });
      },
    },
    dialog: {
      get() {
        return this.dialogState.reserva;
      },
      set(value) {
        this.$store.commit("boxes/SET_DIALOG", { value, dialog: "reserva" });
      },
    },

    dateError() {
      if (this.res.date && !this.allowedDates(this.res.date)) return true;
      return false;
    },
    horarioError() {
      if (this.res.date && this.res.start && this.res.end) {
        let dh = this.getDayTimetable()(
          this.res.date,
          this.$store.getters["auth/isStudio"]
            ? this.$store.state.auth.user.studio.id
            : this.$store.state.boxes.studioId,
          this.res.tattooer
        );
        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.res.start && h[1] >= this.res.end) error = false;
        });
        return error;
      }
      return false;
    },

    timeError() {
      if (this.res.start != undefined && this.res.end != undefined) {
        let s = this.res.start.split(":");
        let e = this.res.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] >= e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="sass" scoped>
label
  display: block
  height: 25px
</style>
